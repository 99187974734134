<!--  -->
<template>
  <div class="recordAll" id="recordAll">
    <div class="record" :style="`margin-left:${recordLeft}`">
      <span
        class="btn"
        :id="`id${index}`"
        v-for="(item, index) in tags"
        :key="index"
        @click="changeRecord(item, index)"
        :class="[item.path == activerouter ? 'btn-active' : '']"
        >{{ item.meta.name }}
        <span
          class="record-x"
          @click.stop="close(item, index)"
          v-if="index != 0"
          >x</span
        ></span
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  props: {},
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      recordLeft: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {
    changeRoute() {
      return this.$route;
    },
    activerouter() {
      return this.$route.path;
    },
    tags() {
      return this.$store.state.history;
    },
  },
  //监控data中的数据变化
  watch: {
    changeRoute(info) {
      this.setHistory(info);
    },
  },
  //方法集合
  methods: {
    close(item, index) {
      if (index == 0) {
        return;
      }
      this.$store.commit("getroute", { index: index });
      if (this.rou == item.fullPath) {
        this.$router.push(this.tags[index - 1].fullPath);
      }
      // let rou = this.$route.path;
      // let num = this.tags.findIndex(function (item) {
      //   return item.path == rou;
      // });
      // this.setRecord(num);
    },
    changeRecord(item, index) {
      if (this.$route.path == item.path) {
        return;
      }
      this.$router.push(item.fullPath);
    },
    setHistory(info) {
      this.rou = this.$route.fullPath;
      let arr = this.tags;
      let that = this;
      let state = arr.some(function (item, index) {
        if (item.path == info.path) {
          return that.changeHis(item, info, index);
        }
      });
      if (!state) {
        this.$store.commit("getroute", info);
      //  console.log("测试");
      } else {
    //    console.log("已有了");
      }
      this.$nextTick(() => {
        let index = this.tags.findIndex(function (item) {
          return item.path == info.path;
        });
        this.setRecord(index);
      });
    },
    //判断是否需要增加
    changeHis(item, info, index) {
      if (item.fullPath != info.fullPath) {
        this.$store.commit("getroute", { index2: index, info: info });
        console.log("假的");
        return false;
      } else {
     //   console.log("真的");
        return true;
      }
    },
    setRecord(index) {
    //  console.log(index);
      let width = 120;
      let widthAll = document.querySelector(`#recordAll`).offsetWidth;
      if (widthAll == 0) {
        widthAll = 1000;
      }
      let itemWidth = index * width + width;
      if (itemWidth > widthAll) {
        let recordLeft = itemWidth - widthAll;
        this.recordLeft = -recordLeft + "px";
      } else {
        this.recordLeft = "0";
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setHistory(this.$route);
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.record {
  display: inline-flex;
  height: 30px;
  align-items: center;
  box-sizing: border-box;
  padding-top: 2px;
  white-space: nowrap;
  transition: all 0.3s;
}
.recordAll {
  overflow-y: hidden;
  overflow-x: scroll;
  width: 99%;
}
.recordAll::-webkit-scrollbar {
  height: 0;
}
.btn {
  padding: 5px 10px;
  font-size: 12px;
  color: #409eff;
  box-sizing: border-box;
  white-space: nowrap;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #eee;
  /* border-right: 1px solid #eee;
  border-top: 1px solid #eee; */
  transition: width 0.3s;
  width: 100px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  border-radius: 6px;
  overflow: hidden;
}
.btn-active {
  color: #fff !important;
  background: #409eff !important;
}
.record-x {
  display: none;
}
.btn:hover > .record-x {
  display: block !important;
}
.btn:hover {
  width: 120px;
}
</style>