<!--  -->
<template>
  <div>
    <!-- <img src="@/assets/404.png" alt="" width="400" />
    <el-button type="warning" @click="goHome">返回上一页</el-button> -->
    <el-table :data="list1" style="width: 100%">
      <el-table-column prop="sex" label="性别" width="180"> </el-table-column>
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column prop="age" label="年龄"> </el-table-column>
    </el-table>
    <el-button @click="add">测试</el-button>
  </div>
</template>

<script>
import { number } from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      list1: [],
      list2: ["张三2", "李四2", "王五2", "赵六"],
      //第一次点击的元素
      index1: 0,
      //松手后的元素
      index2: "zj",
      allIndex1: 0,
      allIndex2: 0,
      flag: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    add() {
      let obj = { name: "张三", sex: "男", age: "14" };
      console.time();
      for (let index = 0; index < 100000; index++) {
        this.list1.push(obj);
      }
      console.log("完成");
      console.timeEnd();
    },
    goHome() {
      this.$router.go(-1);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.cw {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cw {
  display: flex;
}
.img {
  flex: 1;
  width: 700px;
  height: 500px;
}
.backhome {
  width: 500px;
  height: 50px;
  position: absolute;
  bottom: 100px;
}
.list {
  width: 100px;
  border: 1px solid red;
  /* margin: auto; */
  margin: 10px auto;
}
.goods {
  margin-left: 20px;
}
.list-all {
  width: 105px;
  border: 1px solid black;
  margin-left: 20px;
  height: 500px;
  padding: 20px;
}
/* 偶数行 */
.tab:nth-child(even) {
  background: rgb(196, 201, 204);
}
.tab {
  width: 500px;
  padding: 10px;
  display: flex;
  border: 1px solid #ccc;
}
.tab div {
  flex: 1;
}
</style>