<template>
  <div class="menu-bug-wrap">
    <div v-for="menu in menuList" :key="menu.id">
      <!-- 如果当前有子菜单，则显示 el-submenu ，在el-subment 里调用 递归组件 -->
      <el-submenu
        v-if="menu.son"
        :index="menu.name"
        :key="menu.id"
        @mouseenter.stop="enter"
      >
        <template slot="title">
          <i :class="menu.icon" v-if="menu.icon"></i>
          <i class="el-icon-menu" v-else></i>
          <span :style="isCollapse ? 'visibility:hidden' : ''">
            {{ menu.name }}
          </span>
        </template>
        <!-- 调用自身  此处是重点-->
        <MenuTree :menuList="menu.son"></MenuTree>
      </el-submenu>
      <!-- 如果没有子菜单，则显示当前内容 -->
      <el-menu-item v-else :index="menu.path" :key="menu.id">
        <i :class="menu.icon" v-if="menu.icon"></i>
        <i class="el-icon-menu" v-else></i>
        <span :style="isCollapse ? 'visibility:hidden' : ''">
          {{ menu.name }}
        </span>
      </el-menu-item>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "MenuTree",
  props: {
    menuList: {
      type: Array,
      required: false,
    },
    isCollapse: false,
  },

  methods: {
    enter() {
      return;
    },
    changeColl() {
      let info = !this.isCollapse;
      this.$store.commit("changeColl", info);
    },
  },
};
</script>
 
<style scoped>
/* :style="isCollapse ? 'display:none' : ''" */

.el-menu--popup-right-start >>> {
  display: none !important;
}
.el-menu--collapse
  > .menu-bug-wrap
  >>> div
  > .el-submenu
  > .el-submenu__title
  > .el-submenu__icon-arrow {
  display: none;
}
.menu-bug-wrap {
}
.icon-lev {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  padding: 0px 7px;
  line-height: 22px;
  font-size: 13px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 3px;
}
</style>