import Vue from 'vue'
import store from "../store/index";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
//主页
import Home from '../views/Home/Home.vue'
//登录
import Login from '../views/Login/Login.vue'
import Report from '../views/Home/404.vue'
NProgress.configure({
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
})
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    redirect: '/index',
    component: Home,
    children: [
      {
        path: '/index',
        component: () => import("@/views/Home/Index/index.vue"),
        meta: { name: '控制台' }
      },
      {
        path: '/banner',
        component: () => import("@/views/Home/Banner/banner.vue"),
        meta: { name: '轮播图管理' }
      },
      {
        path: '/advertising',
        component: () => import("@/views/Home/advertising/advertising.vue"),
        meta: { name: '广告图管理' }
      },
      {
        path: '/hotSale',
        component: () => import("@/views/Home/HotSale/hotSale.vue"),
        meta: { name: '热卖列表' }
      }, {
        path: '/order',
        component: () => import("@/views/Home/Order/order.vue"),
        meta: { name: '订单列表' }
      }, {
        path: '/gathering',
        component: () => import("@/views/Home/Order/gathering.vue"),
        meta: { name: '收款流水列表' }
      },{
        path: '/orderFlowList',
        component: () => import("@/views/Home/Order/orderFlowList.vue"),
        meta: { name: '交易流水列表' }
      },{
        path: '/orderInfo',
        component: () => import("@/views/Home/Order/orderInfo.vue"),
        meta: { name: '订单详情' }
      }, {
        path: '/recommend',
        component: () => import("@/views/Home/Recommend/recommend.vue"),
        meta: { name: '推荐列表' }
      }
      , {
        path: '/desk',
        component: () => import("@/views/Home/Desk/desk.vue"),
        meta: { name: '桌号管理' }
      },
      {
        path: '/pos',
        component: () => import("@/views/Home/Pos/index.vue"),
        meta: { name: '小票机列表' }
      },
      {
        path: '/orderList',
        component: () => import("@/views/Home/orderList/orderList.vue"),
        meta: { name: '订单管理（new）' }
      },
    ]
  },
  {
    //登录
    path: '/login',
    name: 'login',
    component: Login
  },
  // 404页面
  {
    path: "*",
    component: Report
  }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // store.commit("getroute",{ path:to.path,query:to.query});
  // console.log(store.state.history);
  NProgress.start();
  let token = sessionStorage.getItem('token')
  if (to.path === '/login') {
    // let info = []
    // router.addRoutes(info)
    return next()
  }
  if (!token) {
    next('/login')
  }
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
export default router
